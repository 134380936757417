import { KeycloakConfig } from 'keycloak-js';

//UAT
const keycloakConfig: KeycloakConfig = {
  realm: 'UATSaaSEHR',
  url: 'https://acc-auth.3ahealth.com/',
  clientId: 'ehr-ui',
};
export const environment = {
  production: false,
  keycloak: keycloakConfig,
  APP_URL: 'https://uat-ehr.3ahealth.com/',
  BACKEND_URL: 'https://uat-ehr-api.3ahealth.com/',
};

// run command
// ng serve --ssl true --ssl-key private-key.pem --ssl-cert certificate.pem --configuration=uat
